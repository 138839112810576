import { createContext, useState, useEffect, useRef } from "react";
import { useToast } from "@chakra-ui/react";
import uploadFile from "../services/uploadFile";
import getDateTime from "../utils/getDateTime";
import getSettings from '../services/Settings/getSettings'
const ChatContext = createContext();

export function ChatProvider({children}){
    const [active, setActive] = useState();
    const [messages,setMessages] = useState([]);
    const [isLoading ,setIsLoading] = useState(false);
    const [downloadModal, setDownloadModal] = useState(
        {
            display:"none",
            url:"",
            type:""
        }
    );
    const messagesEndRef = useRef(null);
    const panelRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({behavior: "instant"})
    }
    const [started, setStarted] = useState(false);
    const [currentFile, setCurrentFile] = useState(null);
    const [settingsServer, setSettingsServer] = useState(null);

    const toast = useToast();

    useEffect(scrollToBottom, [currentFile]);

    useEffect(()=>{
        (
            async function(){
                if(!settingsServer){
                    await getSettings()
                    .then((res)=>setSettingsServer(res.data))
                    .catch((err)=>console.log(err))
                }
            }
        )()
        },[settingsServer]
    )

    async function SendFile({type, socket, active}){
        var dateTime = getDateTime()
        if(currentFile){
            if(type=="image" && currentFile.data.size >= (5 * 1024 * 1024)){ // 5MB
                toast({
                    title: `Imagem muito grande, o tamanho limite é de 5MB`,
                    status: 'error',
                    position: 'top',
                    isClosable: true,
                })
            }
            else if(type=="file" && currentFile.data.size >= (100 * 1024 * 1024)){ // 100MB  
                toast({
                    title: `Arquivo muito grande, o tamanho limite é de 100MB`,
                    status: 'error',
                    position: 'top',
                    isClosable: true,
                })
            }else{
                const response = await uploadFile(currentFile.data, {
                urlServer: active.server,
                fileName: `${active.id}_${new Date().getTime()}.${currentFile.data.name.split('.').pop()}`,
                apiKey: process.env.REACT_APP_KEY
                })
                setCurrentFile(null);
                if(response!=false){
                    toast({
                        title: `Upload bem sucedido`,
                        status:'success',
                        position: 'top',
                        isClosable: true,
                    })
                    socket.emit('chat-message',
                        {
                            id_author:active.id,
                            content:"",
                            fileName: response.fileName,
                            link: response.url,
                            mimetype: response.mimetype,
                            type:type=="image"?"image":"document",
                            room: active.id_room,
                            name_author:active?.name,
                            schedule_message: dateTime.time,
                            channel: active.channel,
                            isSupport: true,

                        },setCurrentFile(null)
                    )
                }else{
                    toast({
                        title: `Falha ao fazer upload do arquivo`,
                        status: 'error',
                        position: 'top',
                        isClosable: true,
                    })
                    setCurrentFile(null)
                }
            }
        }
        
    };

    return <ChatContext.Provider value={{
        active, setActive,
        started, setStarted,
        downloadModal, setDownloadModal,
        messages,setMessages,
        currentFile, setCurrentFile,
        settingsServer, setSettingsServer,
        SendFile,
        scrollToBottom,
        messagesEndRef,
        isLoading ,setIsLoading,
        panelRef
    }}>
       {children} 
    </ChatContext.Provider>



}

export const ChatConsumer = ChatContext.Consumer;

export default ChatContext;