import {Spinner} from './styles/Spinner'
import {ChatContainer} from './components/chatContainer'
import InitialContainer from './InitialContainer'
import getDateTime from './utils/getDateTime'
import { useEffect } from "react"
import useSocket from "./hooks/useSocket"
import useChat from "./hooks/useChat"
import { MdRefresh} from "react-icons/md"
import './App.css'
import {useState} from 'react'


function App(fn) {
  const {socket,SocketEvent, connectSocket, connectError, loading,setConnectError, setLoading} = useSocket();
  const {active, setActive} = useChat();
  const [started, setStarted] = useState(false);

  // Não funciona em navegador antigo
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(
    ()=>{
      if(!params)return;  

      if(!params.name || !params.group){
        setConnectError({
          status: true,
          message: "[1] Não foi possível identificar suas credenciais de acesso"
        }) 
        return;
      }

      const server = process.env.REACT_APP_SERVER;
      if(!server){
         setConnectError({
          status: true,
          message: "Não foi possível localizar o servidor."
        })
        return;
      }
      const userName = params.name;
      const dateTime = getDateTime();
      setActive({
        name: userName,
        id: `@${userName}_${params.server}`,
        id_room: `${userName}_${params.group}`,
        status: 'pending',
        channel:'client',
        schedule: dateTime.time,
        server: server, 
        cns: params?.cns,
      })
     
    },[])

    useEffect(
      ()=>{
        if(!active)return 
        const {server, name, id_room, id} = active;

        if(!server || !name || !id_room || !id){
          setConnectError({
              status: true,
              message: "[2] Não foi possível identificar suas credenciais de acesso"
          })
          return; 
        }

        if(!socket){
            connectSocket({url: server, username:name });
        }
          
      },[active]
    )

    useEffect(
      ()=>{
        if(socket){
          socket.on('connect', () => {
            setLoading(false)
          })
        }
      },[socket]
    )


  useEffect(
    ()=>{
        Notification.requestPermission()
    },[]
  )

  SocketEvent('connect_error', err =>{
    console.log('connect_error',err)
    setConnectError({
      status: true,
      message: "[1] Falha na conexão de socket."
  })
  });
  SocketEvent('connect_failed', err =>{
    console.log('connect_failed',err)
    setConnectError({
      status: true,
      message: "[2] Falha na conexão de socket."
    })
  });
  SocketEvent('disconnect', err =>{
    console.log('disconnect',err)
    setConnectError({
      status: true,
      message: "Conexão de socket perdida"
    })
  });

  let responseConnectError = ()=>(
    <div
      style={
        {
          display:"flex",
          flexDirection:"column",
          width:"100%",
          height:"100vh",
          justifyContent:"center",
          alignItems:"center",
          textAlign:"center"
        }
      }
    >
      <h1 style={{fontSize:"1.2rem"}}>FALHA DE CONEXÃO AO SERVIDOR</h1>
      {connectError.message }
      <div onClick={(e)=>document.location.reload()}
          style={{
            width:"max-content",
            paddingLeft:"3px",
            paddingRight: "3px",
            paddingTop: "3px",
            paddingBottom:"3px",
            border: "1px solid #000000",
            borderRadius: "10px",
            background: "transparent",
            cursor: "pointer",
            marginTop:"10px"
          }}
      >
        <MdRefresh fontSize="25px"/>
      </div>
    </div>
  ) 
  
  let responseLoading = ()=>(
    <div
      style={{
        display: "flex",
        flexDirection:"column",
        width:"100%",
        height:"100vh",
        justifyContent:"center",
        alignItems:"center"
      }} 
    >
      <Spinner />
    </div>
  ) 
  

  return (
    <div className="App"
         style={
           {
            fontFamily: 'Inter, sans-serif'
           }
         }
    >
         {
            connectError.status?
              responseConnectError()
              :loading?
                responseLoading()
                :
                started?
                  <ChatContainer />
                :
                  <InitialContainer setStarted={setStarted} />
         }
    </div>
  );
}
export default App;
