import { Box} from '@chakra-ui/react'

export const BtnSend = ({icon,event}) => {
    return(
        <Box 
            onClick={event?(e)=>event():null}
            cursor={"pointer"} bg={"#FFFFFF"} 
            mt={"5px"}
            ml={"5px"}
            mr={"20px"}
            mb={"5px"}
            borderRadius="30px" 
            pr={"7px"}
            pl={"7px"}
            pb={"7px"}
            pt={"7px"}
            height={'max-content'}
            bg={"#F9f9f9f9"}
            color={"rgb(22, 75, 97)"}
            style={{
                boxShadow: "0px 4px 4px rgb(22, 75, 97,0.5)"
            }}
            _hover={
                {
                opacity: "0.7"
                }
            }
        >   
            {icon}
        </Box>
    )
}