import useChat from "../../hooks/useChat"

export const HeaderChat = () => {
    return(
        <div 

        style={{
            paddingTop:"0px",
            textAlign:'center',
            width: "100%",
            background:"#164B61",
            borderTop:"0px",
            borderBottom:"1px",
            color:"#FFFFFFFF",
        }}
        >
           <h1
            style={{
                fontSize:"medium",
                fontWeight:"700",
                padding: "5px"
            }}>
                <a 
                        style={
                            {
                                cursor:"pointer"
                            }
                        }
                        id="downloadImage"
                        href='/favicon.ico' 
                        download
                    >
                    ATENDIMENTO MACTUS
                    </a>
            </h1>

        </div>
    )

}