/*
    Buscatodas questões de determinado formulário no banco de dados do MacForms
*/
const axios = require('axios')

export default async function getQuestions(idForm) {
    const response = await axios.get(process.env.REACT_APP_API_FORMS+`/question/${idForm}/form`)
            .then(function (response) {
                return response.data.data
            })
            .catch(function (error) {
                return {status:"error", message:`${error}: Não foi possível listar os formulários.`}	
            });
    return response;
}