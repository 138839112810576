import axios from 'axios';
import FormData from 'form-data'
import encryptKey from '../utils/encryptKey';

export default async (file,{urlServer, fileName, apiKey})=>{

    const encryptedKey  = await encryptKey(apiKey);
    var data = new FormData();
    data.append('file', file);
    var config = {
    method: 'post',
    url: `${urlServer}/uploadFile?name=${fileName}`,
    headers: { 
        'Content-Type': 'multipart/form-data',
        'Authorization': `${encryptedKey}`
    },
    data : data
    };

  const response = await axios(config)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
      return false
    });
  return response
}
