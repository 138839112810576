import { useEffect, useState } from "react"
import useChat from "../../../hooks/useChat"
import { Box, Flex, Text } from "@chakra-ui/react"
import {HiOutlineDownload} from "react-icons/hi"
import {RiFileDownloadFill} from "react-icons/ri"
import { IoMdClose } from "react-icons/io";

export const ModalDownload = ()=>{
    const { downloadModal, setDownloadModal } = useChat();
    const [base64, setBase64] = useState('');
    
    useEffect(
        ()=>{
            if(downloadModal.url){
                // convert url image or file to base64
                (async function(){
                    await getBase64File(downloadModal.url).then(
                        (res)=>{
                            setBase64(res);
                        }
                        ).catch(err=>console.log(err))
                })()
            }
        }
        ,[downloadModal]
    )

    async function getBase64File(url){
        const blob = await fetch(url)
        .then((r)=>{
            // get blob file 
            return r.blob()
        })
        .catch((err)=>{
            throw err;
        })
        if(blob){
            return new Promise((resolve, reject) => {
                const reader = new FileReader;
                reader.onerror = reject;
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(blob);
            });
        }
    };

    function verifyClickModal(e){
        if(e.target.id == "backgroundImage"){
            setDownloadModal({
                display: "none",
                url:"",
                type:''
            })
        }
    }

    return(
        <Box 
        id="backgroundImage"
        display={downloadModal.display}
        position="fixed"
        zIndex={1} 
        left= "0"
        bottom= "0"
        width="100%"
        height="100%"
        backgroundColor="rgba(0,0,0,0.9)"
        justifyContent={"center"}
        alignItems={"center"}
        onClick={(e)=>verifyClickModal(e)}
        >
            <Box id="backgroundImage" position={'absolute'} fontSize={'3em'} fontWeight='extrabold' top={3} left={3} color='white'>
                  <IoMdClose id="backgroundImage"/>  
            </Box>
            <Flex flexDirection="column" alignItems="center" id="backgroundImage" >
                {
                    downloadModal.type=="image"?
                    <img 
                        id={`downloadModal-${downloadModal.url}`}
                        alt={downloadModal.fileName}
                        src={downloadModal.url}
                        crossOrigin="anonymous"
                        style={{
                            maxHeight: "90vh",
                            maxWidth: "70%",
                        }}
                    />
                    :
                    <Flex
                    flexDirection="column"
                    alignItems="center"
                    maxHeight="90vh"
                    maxWidth={'70%'}
                    color={'#FFFFFF'}
                    id={`downloadModal-${downloadModal.url}`}
                    >
                        <RiFileDownloadFill fontSize="3rem" />
                        <Text
                            maxWidth={'400px'}
                        >
                            {downloadModal.fileName}
                        </Text>
                    </Flex>
                }
                {
                    base64
                    &&
                    <a 
                        style={
                            {
                                cursor:"pointer",
                                margin:"10px"
                            }
                        }
                        id="downloadImage"
                        href={base64} 
                        download
                        _target="blank"
                    >
                        <div style={{
                                display:"flex",
                                fontWeight:"bold",
                                alignItems:"center",
                                color:"#FFFFFF"
                            }}
                            id="downloadImage" >
                            <p id="downloadImage"style={{fontSize:"14px"}}>
                                DOWNLOAD
                            </p>
                            <HiOutlineDownload id="downloadImage" fontSize={"25px"}/>
                           
                        </div>
                    </a>
                }
            </Flex>
            
        </Box>

    )


}