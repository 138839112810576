import { useEffect, useRef } from 'react';
import {  Spinner } from '@chakra-ui/react';
import { MessageBox } from './MessageBox';
import useChat from "../../../hooks/useChat";
import useSocket from "../../../hooks/useSocket";
import { ModalDownload}  from './ModalDownload';
export const PanelChat = () => {
    const {messages, setMessages,messagesEndRef,isLoading ,setIsLoading,
           panelRef, active} = useChat();
    const {socket,SocketEvent} = useSocket();

    const reqNewMessages = async () => {
        if(active && messages[0]?._id){
            setIsLoading(true)
            await socket.emit("retrieveLast20Messages",
                {
                    room: active.id_room,
                    idLastMessage: messages[0]?._id
                },
                (oldMessages)=>{
                    if(oldMessages && oldMessages.length > 0){
                        if (oldMessages[0].room === active?.id_room) {
                            setMessages([ ...oldMessages,...messages])
                            panelRef.current.children[oldMessages.length].scrollIntoView({ behavior: "instant" })
                        }
                    }
                    setIsLoading(false)
                }
            )
         }
    }
    
    const handleScroll = async (e) => {
        if(e.target.scrollTop == 0){
            reqNewMessages();
        }
    }

    SocketEvent("resGetMessages", (oldMessages) => {
        if(oldMessages && oldMessages.length > 0){
            if (oldMessages[0].room == active?.id_room) {
                setMessages([ ...oldMessages,...messages])
                panelRef.current.children[oldMessages.length].scrollIntoView({ behavior: "instant" })
                setIsLoading(false)
            }
        }else{
            setIsLoading(false)
        }
    })


    return(
        <div
         style={{
             height:"100%",
             width: "100%"
         }}
         id={"scroll"}
         onScroll={(e) => {handleScroll(e)}}
         ref={panelRef}
        >
        {
        isLoading 
            && 
            <div style={{ width:"100%",display:"flex", justifyContent:"center"}}>
                <Spinner size={"lg"}/>
            </div>  
        }
        {
            messages.map((m)=><MessageBox active={active} message={m}/>)
        }
        <div ref={messagesEndRef} />
        <ModalDownload />
        </div>
    )
}