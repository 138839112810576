/*
    Busca o formulário cadastrado no banco de dados do MacForms

*/
const axios = require('axios')

export default  async function getSingleForm(idForm) {
    const response = await axios.get(process.env.REACT_APP_API_FORMS+`/form/${idForm}`)
        .then(function (response) {
            return response.data.data
        })
        .catch(function (error) {
            return {status:"error", message:`${error}: Não foi possível listar o formulário com id ${idForm}.\n${error}`}	
        });
    return response;
}