// /answer/create
/*
   Cria uma resposta para o formulário
*/
const axios = require('axios')
export default async function createAnswer({formId,userName,userPhone,supportName}){
    const response = await axios.post(process.env.REACT_APP_API_FORMS+'/answer',
    {
        formId: formId, 
        userName: userName,
        userPhone: userPhone,
        supportName: supportName
    })
    .then(function (response){
        return response.data.data
    })
    .catch(function (error){
        return {status:"error", message:`${error.response.data.message}`}	
    });
    return response;
}