/*
    Lista as opções de uma questão

*/
const axios = require('axios')

export default  async function getOptions(questionId) {
    if(questionId){
        const response = await axios.get(process.env.REACT_APP_API_FORMS+`/option/${questionId}/question`)
        .then(function (response) {
            return response.data.data
        })
        .catch(function (error) {
            return {status:"error", message:`${error}: Não foi possível listar os formulários.`}	
        });
        return response;
    }else{
        throw new Error("QuestionId não informado ou inválido: "+questionId)
    }
}