import { useEffect, useState } from "react"
import { HeaderChat } from "./HeaderChat"
import { PanelChat } from "./panelChat"
import { InputChat } from "./inputChat"
import { RatingChat } from "./ratingChat.js"
import getDateTime from '../../utils/getDateTime'
import useSocket from '../../hooks/useSocket'
import useChat from '../../hooks/useChat'
import { PreviewSendImageFile } from "./previewSendImageFile"

export const ChatContainer = () => {

    const {socket,SocketEvent} = useSocket();
    const {messages,setMessages, scrollToBottom, currentFile, setCurrentFile, active, settingsServer } = useChat();

    const [nameSup, setNameSup] = useState("");
    const [isScreenActive, setIsScreenActive] = useState(true);

    const notificationAudio = new Audio('alertSound2.wav');
    // Alterar a velocidade do audio
    notificationAudio.playbackRate = 2.0;
    useEffect(() => {
        window.addEventListener('visibilitychange', (e)=>{
            //check if the window is active
            document.visibilityState === 'visible' ? setIsScreenActive(true) : setIsScreenActive(false);
        });

        // cleanup this component
        return () => {
            window.removeEventListener('visibilitychange', (e)=>{});
        };
    }, []);

    useEffect(
        ()=>{
            if(active){ 
                if(active.status!=="finished"){ 
                    notificationAudio.play();
                    joinRoom({
                        id_room:active.id_room,
                        name: active.name,
                        status:active.status,
                        channel: active.channel,
                        schedule: active.schedule,
                        cns: active.cns,
                        isSupport: false
                    })
                }
            }
        },[active]
    )

    SocketEvent("message",(message) => {
        if(message.room === active?.id_room ){
            if(!isScreenActive){
                // alert sound notification
                notificationAudio.play();

                let nameAuthor = message.name_author === "admin" ? "Atendimento Mactus" : message.name_author;
                // notification
                new Notification(nameAuthor, {
                    body: message.content,
                    icon: "https://chat.mactus.online/favicon.ico"
                });
            }
            setMessages([...messages, message]);
            scrollToBottom();
        }
    })
  
    SocketEvent("previousMessages",(previousMessages) => {  
        setMessages(previousMessages);
        scrollToBottom();
    })
  
    SocketEvent("endThisCall",({name_sup}) => {
          if(active!=""){
             setNameSup(name_sup)
             active.status = "finished";
             setTimeout(()=>{
                scrollToBottom();
             },200)
          }
      })
  
    async function joinRoom({id_room,name,status,channel,isSupport, schedule, cns}){
        var dateTime = getDateTime()
        await socket.emit("joinRoom",
        {id_room: id_room, name:name,status:status, channel: channel,
        schedule:schedule, date:dateTime.date, schedule_current:dateTime.time, cns: cns, isSupport:isSupport})
    }

    function getFileFromPasteEvent(event) {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items;
        for (let index in items){
            const item = items[index];
            if (item.kind === 'file'){
                const file = item.getAsFile();
                if(file){
                    if(file?.type.includes("image"))return setCurrentFile({type:"image", data:file});
                    return setCurrentFile({type:"file", data:file})
                }
            }
        }
    }

    function getFileFromDropEvent(event){
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if(file){
            if(file?.type.includes("image"))return setCurrentFile({type:"image", data:file});
            return setCurrentFile({type:"file", data:file})
        }
    }

    return(
        <div 
            style={{
                display: "flex",
                height:"100vh",
                width: "100vw",
                backgroundPosition:"center",
                background:"#FFFFFF",
                flexDirection:"column",
                alignItems:"center",
                justifyContent:"space-between",
                paddingBottom:"0px",
            }}
            onPaste={(e)=>getFileFromPasteEvent(e)}
            onDrop={(e)=>getFileFromDropEvent(e)}
            onDragOver={(e)=>e.preventDefault()}
        >
              <HeaderChat /> 
              {currentFile?
                    <PreviewSendImageFile/>
                : 
                    <>
                        <PanelChat/>
                        {
                            active?.status=="finished"
                                ?settingsServer?.enableRating
                                        ?<RatingChat nameSup={nameSup} idForm={settingsServer.idRatingForm}/>
                                        :
                                        <div style={{textAlign:"center",width:"100%", background:"#f9f9f9f9"}}>
                                            <button onClick={(e)=>document.location.reload()} 
                                                style={{
                                                    borderRadius:"6px",
                                                    background: 'rgb(22, 75, 97)',
                                                    padding:"8px",
                                                    margin: "5px",
                                                    color:"rgb(255, 255, 255)"
                                                }}
                                            >
                                                Novo Atendimento
                                            </button>
                                        </div>
                                :<InputChat/>
                        }
                    </>
                    
              }
              
        </div>
    )
}