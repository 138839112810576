import {useState} from 'react'
import { Box, Input,Tooltip,Popover,PopoverBody,PopoverContent,PopoverTrigger, useToast, useColorMode} from '@chakra-ui/react'
import {BtnSend} from './BtnSend'
import { AttachmentIcon} from '@chakra-ui/icons'
import { IoImage,IoDocumentText} from "react-icons/io5"
import { RiCreativeCommonsZeroLine } from 'react-icons/ri'
import useSocket from '../../../hooks/useSocket'
import useChat from '../../../hooks/useChat';

export const BtnAttach = ()=>{
    const { SendFile, currentFile, setCurrentFile, active} = useChat();
    const [image, setImage] = useState();
    const toast = useToast()
    const {socket} = useSocket();

    return(
        <Popover>
            <PopoverContent 
            _focus={{ boxShadow:"none" }}
            width="max-content" bg="transparent" border="none" >

                <PopoverBody>
                    <Input value={currentFile} onChange={(e)=>setCurrentFile({data: e.target?.files[0], type:"image"})} id="inputImage" display="none" type="file"  accept="image/*"/>
                    <Input value={currentFile} onChange={(e)=>setCurrentFile({data: e.target?.files[0], type:"file"}) } id="inputFile" display="none" type="file"/>

                    <Tooltip label="Imagem" aria-label="Enviar imagem">
                        <label for="inputImage">
                            <BtnSend   icon={<IoImage  fontSize={"25px"}/>} />
                        </label>
                    </Tooltip>
                    
                    <Tooltip label="Arquivo" aria-label="Enviar arquivo">
                        <label for="inputFile">
                            <BtnSend  icon={<IoDocumentText  fontSize={"25px"}/>} />
                        </label>
                    </Tooltip>
                </PopoverBody>
            </PopoverContent>
            <PopoverTrigger>

                    <Box 
                        tabIndex={0}
                        cursor={"pointer"}
                        m={"5px"}
                        borderRadius="30px" 
                        pl={"6px"}
                        pr={"6px"}
                        pt={"2px"}
                        pb={"5px"}
                        height={'max-content'}
                        bg={"#F9f9f9f9"}
                        border={'1px'}
                        color={"rgb(22, 75, 97)"}
                        style={{
                            boxShadow: "0px 4px 4px rgb(22, 75, 97,0.5)"
                        }}
                        _hover={
                            {
                            opacity: "0.7"
                            }
                        }
                    >   
                        <AttachmentIcon  fontSize={"19px"}/>
                    </Box>

            </PopoverTrigger>

        </Popover>

    )
  
}