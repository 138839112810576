import styled from 'styled-components'


export const TextArea = styled.textarea`
    font-family: Inter, sans-serif;
    background-color: transparent;
    border: none;
    overflow: hidden;
    resize: none;
    outline: none;
    flex-grow:1;
    height: 50px;
    width:100%;
    font-size:14px;
    padding: 8px;
    color:#FFFFFF;
    ::placeholder{
        color:#FFFFFF;
        opacity: 0.5; 
    }
`