import instanceAPI from './instance'

export default async function getSettings(){

    const api = await instanceAPI()
    const response =await api.get('/settings/list')
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        return {status:"error", message:`${error.response?.message}`}
      });
    return response;
}