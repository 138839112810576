import CryptoJS,{ AES } from "crypto-js";

export default async function encryptKey(key){
    try{
        let secret = process.env.REACT_APP_SECRET;
        let encryptedBase64Secret = CryptoJS.enc.Utf8.parse(secret).toString(CryptoJS.enc.Base64);
        let parsedBase64Secret = CryptoJS.enc.Base64.parse(encryptedBase64Secret);
        let plaintText = key;
        
        const encryptedData = CryptoJS.AES.encrypt(plaintText, parsedBase64Secret, {
           mode: CryptoJS.mode.ECB,
           padding: CryptoJS.pad.Pkcs7
        });
  
        return encryptedData.toString();
     }
     catch{return null}
    
}


   