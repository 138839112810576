

export const ScheduleMessage = ({children, color}) => {

    return(
        <p style={{
            fontSize:"10px",
            color:`${color}`,
            textAlign:"end"
        }}>
            {children}
        </p>
        
    )
}