import { Box, useColorMode, Flex,Text } from "@chakra-ui/react"
import useChat from '../../hooks/useChat'
import useSocket from '../../hooks/useSocket'
import { IoMdClose } from "react-icons/io";
import { IoSend} from "react-icons/io5"

import {RiFile3Fill}from 'react-icons/ri'
import { useEffect,useState } from "react";
export const PreviewSendImageFile = () => {

    const { colorMode } = useColorMode();
    const fontColor = { light: 'light.fontColor', dark: 'dark.fontColor' }
    const background = { light: 'light.background', dark: 'dark.background' }
    
    const {SendFile, currentFile, setCurrentFile, scrollToBottom, active} = useChat();
    const {socket } = useSocket();
    const [URLFilePreview,setURLFilePreview] = useState('');

    useEffect(
        ()=>{
            if(currentFile.data){
                const fileReader = new FileReader();
                fileReader.readAsDataURL(currentFile.data)
                fileReader.onload = () =>{
                    setURLFilePreview(fileReader.result);
                }
            }
        },[currentFile]
    )
    

    const formatBytes = function (bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    return (
        <Flex position={'absolute'}flexDir={"column"} 
             height={"100%"}
            justifyContent={"center"} alignItems={"center"}
            color={fontColor[colorMode]}
        >
            <Flex aria-label="fechar" label={'Fechar'} position={'fixed'} top={10} left={3} width={"100%"} 
            justifyContent={"flex-start"} alignItems={"center"} 
            onClick={()=>{setCurrentFile(null);setURLFilePreview('');}}
            >
                 <IoMdClose  fontSize={"40px"} />
            </Flex>
            {currentFile.type=='image'
            ?
                <Flex justifyContent={"center"} alignItems={"center"}
                 width={'85%'} height={'70%'} >
                    <img style={{
                        maxWidth: "100%",
                        maxHeight:"100%"
                    }} 
                    src={URLFilePreview} />
                </Flex>
            :
                <Flex height={'70%'} flexDir={'column'} alignItems={'center'} justifyContent="center">
                    <RiFile3Fill fontSize={"60px"}/>
                    <Flex  width={"100%"} justifyContent={"space-between"}>
                        <Text marginRight={'10px'}> Nome:  </Text>
                        <Text  fontSize={"18px"}>{currentFile?.data.name}</Text>
                    </Flex>
                    <Flex width={"100%"} justifyContent={"space-between"}>
                        <Text marginRight={'10px'}> Tamanho:  </Text>
                        <Text fontSize={"18px"}>{formatBytes(currentFile?.data.size,2)}</Text>
                    </Flex>
                </Flex>
            }
                
            <Box
                position={'relative'}
                color={background[colorMode]}
                _hover={{
                    opacity:"0.7",
                }}
                onClick={()=>SendFile({ type: currentFile?.type, socket:socket, active:active})}
            >
                <IoSend fontSize={"30px"}/>
            </Box>
        </Flex>
    )
}