
/*
   Cria uma resposta para o formulário

*/
const axios = require('axios')

export default async function createAnswerQuestion({formId, questionId, answerId, data}) {
    const response = await axios.post(process.env.REACT_APP_API_FORMS+'/answerquestion',
    {formId: formId, questionId: questionId, answerId: answerId, data: data})
            .then(function (response) {
                return response.data.data
            })
            .catch(function (error) {
                console.log(error)
                return {status:"error", message:`${error.response.data.message}`}	
            });
    return response;
}