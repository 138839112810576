import styled from 'styled-components'


export const Spinner = styled.div`
  border: 2px solid #f3f3f3; 
  border-top: 2px solid #606060; 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.7s linear infinite;

  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }

`