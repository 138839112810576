import {Content} from './Content'
import { ScheduleMessage } from './ScheduleMessage'

export const MessageBox = ({message, active}) => {

    
    
    return(
        <>
        {message.id_author==0?
        <div 
            style={{
                display: "flex",
                width:"100%",
                justifyContent:"center",
            }}>
            <div 
                style={{
                    background:"#E5F6FD",
                    minWidth:"100px",
                    maxWidth:"450px",
                    paddingRight:"10px",
                    paddingLeft:"10px",
                    paddingTop:"6px",
                    margin:"10px",
                    borderRadius:"8px 8px 8px 8px",
                    boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
                    fontSize:"12px",
                    textAlign:"center",
                }}
            >
                <Content message={message} fontWeight={"500"} color={"#164B61"}/>
                <ScheduleMessage>
                    {message.schedule_message}
                </ScheduleMessage>
            </div>
        </div>
        :message.id_author == active.id?
        <div
            style={{
                display: "flex",
                width:"100%",
                justifyContent:"flex-end",
            }}>
            <div 
                style={{
                   background:"#164B61",
                   minWidth:"100px",
                   width:"max-content",
                   maxWidth:"300px",
                   paddingRight:"10px",
                   paddingLeft:"10px",
                   paddingTop:"5px",
                   margin:"10px",
                   borderRadius:"12px 0px 12px 12px",
                   boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"
                }}
            >
                <Content message={message} color={"#FFFFFF"}/>
                    
                <ScheduleMessage color={"#FFFFFF"}>
                    {message.schedule_message}
                </ScheduleMessage>
            </div>
        </div>
        :
        <div
            style={{
                display: "flex",
                width:"100%",
                justifyContent:"flex-start",
            }}
        width="100%" justifyContent="flex-start">
            <div 
                style={{
                    background:"#ffffff",
                    minWidth:"100px",
                    width:"max-content",
                    maxWidth:"300px",
                    paddingRight:"10px",
                    paddingLeft:"10px",
                    paddingTop:"5px",
                    margin:"10px",
                    borderRadius:"0px 12px 12px 12px",
                    boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)"
                }}
            >
                <p style={{
                    color:"rgb(55, 182, 237)",
                    fontSize:"12px",
                    textAlign:"start"
                }}>
                    {message.name_author}
                </p> 
                <Content message={message} color={"#164B61"} />
                <ScheduleMessage color={"rgb(55, 182, 237)"}>
                    {message.schedule_message}
                </ScheduleMessage>
                 
            </div>
        </div>
            
        }
       </>
        )
   
}