import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import {RiFileDownloadFill} from 'react-icons/ri'
import useChat from '../../../../hooks/useChat'

export const Content = ({message,color, fontWeight}) => {
    const {setDownloadModal} = useChat();

    return (
        <>
            {message.type == "text"?
                <pre 
                style={{font:"Inter",color:color,fontFamily:"sans-serif",fontWeight:fontWeight,
                        whiteSpace:"pre-wrap", maxWidth:"250px", minWidth:"50px"}}>
                    {message.content}
                </pre> 
            :
            message.type == "image"?
                <img  onClick={()=>setDownloadModal({display: "flex", url:message.link, type:'image'})} src={message.link} style={{maxWidth:"250px", minWidth:"200px"}}  />
            :message.type == "document"?
                <div
                 style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-evenly",
                    color: color,
                    fontWeight : "700",
                    fontFamily:"monospace",
                    width:"250px",
                    fontSize:"18px",
                    cursor:"pointer"
                 }}
                 onClick={()=>setDownloadModal({display: "flex", url:message.link, type:"file", fileName:message.fileName})}
                 >  
                  <RiFileDownloadFill fontSize={'30px'} style={{marginRight:"10px"}}/>
                    <Text  
                    maxWidth={'200px'}
                    overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap"
                    >
                        {message.fileName}
                    </Text>
                </div> 
            :
                <p
                style={{
                    color: color,
                    fontWeight : "700"
                }}
                >
                    <i>MENSAGEM INVÁLIDA({message.type})</i>
                </p>  
            }
        </>
    )

}