import { createContext, useState, useEffect } from "react";
import io from 'socket.io-client'
import encryptKey from '../utils/encryptKey'
const SocketContext = createContext();

export function SocketProvider({children}){

    const[socket, setSocket] = useState(null);
    const[connectError, setConnectError] = useState({
        status: false,
        message: ""
    });
    const[loading, setLoading] = useState(true);

    const connectSocket = async ({url, username})=>{
        const encryptedKey = await encryptKey(process.env.REACT_APP_KEY);
        setSocket(io(url,
        {
            reconnection: false,
            auth:{
                key:encryptedKey,
            },
            query:{
                type: "client",
                username: username, 
             }
        }
        ))
    }
    
    const SocketEvent= (eventName, cb)=>{
        useEffect(() => {
            socket?.on(eventName, cb)
    
            return function useSocketCleanup() {
            socket?.off(eventName, cb)
            }
        }, [eventName, cb])

        return socket
    }  
    
    return <SocketContext.Provider value={{
        socket,
        connectSocket,
        SocketEvent,
        connectError,
        setConnectError,
        loading,
        setLoading
    }}>
       {children} 
    </SocketContext.Provider>
    
}

export const SocketConsumer = SocketContext.Consumer;

export default SocketContext;