
import { useEffect, useState } from 'react'
import { Button } from '@chakra-ui/react'
import {IoSend} from 'react-icons/io5'
import { BtnAttach } from './BtnAttach'
import { BtnSend } from './BtnSend'
import getDateTime from '../../../utils/getDateTime'
import useSocket from '../../../hooks/useSocket'
import useChat from '../../../hooks/useChat'
import { TextArea } from '../../../styles/TextArea'
export const InputChat = () => {

    const [text, setText] = useState();
    const {socket} = useSocket();
    const {active} = useChat();
    function sendMessage(){
        var dateTime = getDateTime()
        if(text!=""){
           
            socket.emit('chat-message',
                {
                    id_author:active.id,
                    content:text,
                    type:"text",
                    room: active.id_room,
                    name_author:active?.name,
                    schedule_message: dateTime.time,
                    channel: active.channel,
                    isSupport: true,
                }
            )
        setText('')
        }
    };

    return(
        
           <div
            style={{
                display: "flex",
                padding:"0px",
                borderBottom:"1px",
                justifyContent:"center",
                background:"rgb(22, 75, 97)",
                width:"100%",
                color:"#FFFFFF"
            }}
           >
               {active.status!="finished"?
                <>
                    <BtnAttach/>
                    <TextArea
                            onKeyDown={event => event.key === "Enter" && text=="" && !event.shiftKey  ? event.preventDefault():null}
                            onKeyUp={event => event.key === "Enter" && !event.shiftKey ?sendMessage():null}
                            value={text}
                            onChange={(e)=>setText(e.target.value)}
                            
                            placeholder="Digite aqui sua mensagem"
                    >
                    </TextArea>
                    <BtnSend event={sendMessage} icon={<IoSend fontSize={'16px'}/>}/> 
                </>
               :
                <></>
                //<Button onClick={(e)=>document.location.reload()} margin={"5px"} color={"rgb(22, 75, 97)"}>Novo Atendimento</Button>
               
               }
                
           </div> 
        
    )

}