import axios from 'axios'
import encryptKey from '../../utils/encryptKey'
export default async function instance(){
    const baseUrlServer = process.env.REACT_APP_SERVER
                            
    const encryptedKey = await encryptKey(process.env.REACT_APP_KEY);
    return axios.create({
        baseURL: baseUrlServer,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${encryptedKey}`
        }
    }) 
}



















