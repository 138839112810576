import { useEffect, useState, useRef } from 'react';
import getSingleForm from '../../../services/MacForms/Form/getSingleForm';
import getQuestions from '../../../services/MacForms/Question/getQuestions';
import getOptions from '../../../services/MacForms/Option/getOptions';
import createAnswer from '../../../services/MacForms/Answer/createAnswer';
import createAnswerQuestion from  '../../../services/MacForms/Answer/createAnswerQuestion';
import './index.css'
import { Spinner, Radio, RadioGroup } from '@chakra-ui/react';

import useChat from '../../../hooks/useChat';
export const RatingChat = ({idForm, nameSup}) => {
    const [form, setForm] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [options, setOptions] = useState(null);
    const [formattedQuestions, setFormattedQuestions] = useState([]);
    const [minimized, setMinimized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ratingIsFinished, setRatingIsFinished] = useState(false);
    const [answers, setAnswers] = useState([]);
    const { active } = useChat();
    const inputValue = useRef([]);

    useEffect(
        ()=>{
            requestDataToRating();
        }, [form,questions, options]
    )

    async function requestDataToRating(){
        if(!form){
            await getSingleForm(idForm)
                .then((res)=>setForm(res))
                .catch((err)=>console.log(err))
        }

        if(form && !questions){
            await getQuestions(form?.uuid)
                .then((questions)=>{
                    setQuestions(questions)
                })
                .catch((err)=>console.log(err))
        }

        if(questions && !options){
            let auxOptions = [];
            for(let i = 0; i < questions.length; i++){
                await getOptions(questions[i].uuid)
                        .then((res)=>auxOptions.push(res))
                        .catch((err)=>console.log(err))
            }
            setOptions(auxOptions)
        }
        if(form && questions && options){
            let auxAnswers = [];
            questions.map(
                (q)=>{
                    auxAnswers.push({
                        questionId: q.uuid
                    })
                }
            )
            setAnswers(auxAnswers)
            setFormattedQuestions(formatQuestions());

        }
    }
    
    function formatQuestions(){
        let nformattedQuestions = [];
        questions?.map((question,index)=>{ 
            nformattedQuestions?.push({
                uuid: question.uuid,
                title: question.title,
                options: options[index]
            })
        });
        return nformattedQuestions;
    }

    async function sendRating(e){
        setIsLoading(true);
        // Verifica se existe alguma resposta 
        if(answers.some((element, index, array)=>element.optionId)){
            await createAnswer({formId: form.uuid, userName:active?.name || "", userPhone: "", supportName: nameSup})
            .then((answer)=>{
                questions?.map(async(question,index)=>{
                    await createAnswerQuestion({
                        formId:form.uuid,
                        questionId: question.uuid,
                        answerId: answer.uuid,
                        data: answers[index].optionId,
                    })
                    .then((res)=>{
                        console.log(res)
                        console.log("Avaliação enviada com sucesso!")
                    })
                    .catch((err)=>console.log(err))
                });
                setMinimized(true);
                setRatingIsFinished(true);
            })
            .catch((err)=>console.log(err))
        }
        setIsLoading(false);
    }

    function handleAnswersChange(currentAnswer){
        let auxAnswers= [...answers]
        var indexAnswers = answers.findIndex(ans => ans.questionId == JSON.parse(currentAnswer)?.questionId)
        auxAnswers[indexAnswers] = {
            questionId: JSON.parse(currentAnswer)?.questionId,
            optionId: JSON.parse(currentAnswer)?.optionId
        }
        setAnswers(auxAnswers)
    }

    const ratingForm = ()=>{
        if(form && questions){
            return(
                <div style={{
                    width: "80%",
                }}>
                    { 
                        formattedQuestions?.map((question,index)=>{
                        return(
                            <div key={index} style={{
                                background: '#F9F9F9',
                                marginTop:10,
                                padding: 8,
                                borderRadius: 5,
                                width: "100%",
                                maxWidth:"400px",
                                fontSize: "0.7em"
                            }} >
                                <div className="question" 
                                style={{
                                    fontWeight: "700",
                                    fontSize:"16px"
                                }}
                                >{question.title}</div>
                                
                                <div className="options">
                                    <RadioGroup value={JSON.stringify(answers[index])} onChange={handleAnswersChange}>
                                    {
                                        question.options?.map((option,i)=>{
                                            return(
                                                <div key={i} style={{display: "flex", alignItems:"center", marginBottom:"3px"}}>
                                                    <Radio color={'blackAlpha.900'}   type="radio" id={option.uuid} 
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                        value={JSON.stringify({questionId:question.uuid, optionId:option.uuid})}
                                                    />
                                                    <div className="option"
                                                    style={{
                                                        fontWeight: "500",
                                                        fontSize:"14px"
                                                    }}
                                                    >{option.title}</div>
                                                </div>
                                            )
                                    })
                                    }
                                    </RadioGroup>
                                </div>
                            </div>
                        )
                    })
                    }  
                </div>
            )
        }
    }

    const ratingLoading = ()=>{
        return(
        <div style={{display:"flex",width: "100%", justifyContent:"center", alignItems:"center", padding:"10px"}}>
            <Spinner />
        </div>
        )
    }

    return(
        <div style={{
            width: "100%",
            background:"#F9F9F9F9",
            display: "flex",
            justifyContent:"center",
            maxHeight: "75vh"
        }}>
            <div
                style={
                    {
                        width: "100%",
                        display: "flex",
                        justifyContent:"center",
                        alignItems:"center",
                        flexDirection:"column",
                        background: "rgb(22, 75, 97, 0.8)"
                    }
                }    
            >
                {
                    ratingIsFinished 
                        ?null
                        :minimized
                            ?null
                            :isLoading
                                ?ratingLoading()
                                :ratingForm()
                }

                {
                    ratingIsFinished
                    ?<div style={{width:"100%", textAlign:"center"}}>
                        <button onClick={()=>window.location.reload()}
                        style={{fontSize: "0.7em", borderRadius:"6px", padding:"8px",
                            margin:"10px", background: "#E5F6FD",color:"rgb(22, 75, 97)"}}>
                            Novo atendimento
                        </button>
                     </div>
                    :
                        <div 
                        style={{
                            display: "grid",
                            gridTemplateColumns: "50% 50%",
                            width: "100%",
                            marginTop: "10px",
                        }}>
                        <div style={{width:"100%", textAlign:"center"}}>
                            <button 
                            onClick={()=>window.location.reload()}
                            style={{fontSize: "0.7em", borderRadius:"6px", padding:"10px",
                                margin:"10px", background: "transparent",color:"rgb(255,255,255)",
                            }}>
                                <u>Novo atendimento</u>
                            </button>
                        </div>
                        <div style={{width:"100%", textAlign:"center"}}>
                            <button onClick={()=>sendRating()}
                            style={{fontSize: "0.7em", borderRadius:"6px", padding:"8px",
                                margin:"10px", background: "#E5F6FD",color:"rgb(22, 75, 97)"}}>
                                Enviar Avaliação
                            </button>
                        </div>
                     </div>
                    
                }
                
               
            </div>
        </div>
    )
};