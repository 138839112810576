import { useEffect, useState } from "react";
import useChat from "./hooks/useChat";
import getDateTime from "./utils/getDateTime";
import { useToast } from "@chakra-ui/react";

const InitialContainer = ({setStarted}) =>{
    
    const {settingsServer} = useChat();
    const [workSchedule, setWorkSchedule] = useState(null);
    const toast = useToast();
    useEffect(
        ()=>{
            if(settingsServer){
               setWorkSchedule(settingsServer.workschedule?.split('_'))
            }
        },[settingsServer]
    )

    function handleStart(){
        const { time } = getDateTime();
        
        if(
            (
                time >= workSchedule[0]
                &&
                time <= workSchedule[1]
            )
            ||
            (
                (workSchedule[2] && workSchedule[3]) //Verifica se existe o segundo período
                &&
                time >= workSchedule[2]
                &&
                time  <= workSchedule[3]
            ) 
        ){
           return setStarted(true)
        }
        return toast({
            title: `Fora do horário de atendimento`,
            status: 'error',
            position: 'top',
            isClosable: true
        })

    }


    return(
        <div style={
            {
                width: "100%",
                height: "100vh",
                background: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }
        }> 
         {
            workSchedule &&
                <div>
                    <h2 style={
                        {
                            color: "#164B61",
                            fontSize: "24px",
                            fontWeight: "bold",
                            margin: "15px",
                        }
                    }>
                        Horário de Atendimento
                    </h2>
                    <p style={{
                        textAlign:"center"
                    }}>
                        Segunda a Sexta-feira<br/>
    {
                            String(workSchedule[0])+" às "+String(workSchedule[1])
                        } <br/>
                        {
                            (workSchedule[2] && workSchedule[3]) 
                            &&
                            String(workSchedule[2])+" às "+String(workSchedule[3])
                        }  
                    </p>
                </div>
            }
            <p 
                style={
                    {
                        margin: "15px",
                        background:"#E5F6FD",
                        padding: "10px",
                        borderRadius: "10px"
                    }
                }
            >
                Ao clicar no botão abaixo, você estará entrando em contato direto com a nossa central de atendimento.<br/>
                Aguarde até que um dos nossos colaboradores possa atende-lo.
            </p>
            
            <button onClick={()=>{
                if(workSchedule){
                    return handleStart()
                }
                return setStarted(true)
            }}
            style={{ background:"#164B61",color:"#FFFFFF", borderRadius:"10px", padding:"10px", height:'max-content' }}>
               <strong> 
                   INICIAR ATENDIMENTO 
               </strong>
            </button>
        
        </div>
    )
} 
export default InitialContainer;